import {BrowserRouter, Navigate, Route, Routes as RoutesReact} from "react-router-dom";
import Background from "./components/Background";
import ProtectedRoute from "./components/ProtectedRoute";
import JobCreation from "./pages/JobCreation";
import React, {useContext} from "react";
import {authContext} from "./context/auth";
import Spinner from "./components/Spinner";
import CenterContainer from "./components/CenterContainer";

export default function Routes() {
    const {loading} = useContext(authContext);
    if (loading) {
        return (<CenterContainer>
            <Spinner />
        </CenterContainer>)
    }
    else {
        return (
            <BrowserRouter>
                <RoutesReact>
                    <Route element={<>
                        <Background /><ProtectedRoute /></>}>
                        <Route path={'/jobCreation'} element={<JobCreation />}/>
                        {/*<Route path={'/jobs'} element={<Jobs />}/>*/}
                        <Route path={'*'} element={<Navigate to={"/jobCreation"} />}></Route>
                    </Route>
                </RoutesReact>
            </BrowserRouter>
        );
    }
}