import Papa from "papaparse";

export default function FileInput({setInputs, fileRef}) {
 /*   async function onFileChange() {
        const file = fileRef.current.files[0];
        console.log(file);

        const parsed = await new Promise((resolve, reject) => {
            Papa.parse(file, {
                preview: 1,
                delimiter: ",",
                header: true,
                complete: (results) => {
                    resolve(results.data);
                },
                error: (e) => {
                    reject(e);
                }
            });
        });

        console.log(parsed);

        setInputs(prev => {
            return {
                ...prev,
                amountOfSlowChanging: parsed[0].slow_changing,
                amountOfFastChanging: parsed[0].fast_changing,
                amountOfEvergreen: parsed[0].nontimesensitive
            };
        });
    }*/

    return (
        <div className="mb-3">
            <label htmlFor="formFile" className="form-label">JSON file:</label>
            <input className="form-control" type="file" id="formFile" ref={fileRef} required={true}/>
        </div>
    );
}