import {useContext} from "react";
import {authContext} from "../context/auth";
import {Navigate, Outlet} from "react-router-dom";
import Navbar from "./Navbar/Navbar";

export default function ProtectedRoute() {
	const {user} = useContext(authContext);


	if (!user) {
		return  window.location.replace(process.env.REACT_APP_PM_URL + '/login?fromQpd=true');
	}
	else {
		return (
			<>
				<Navbar />
				<Outlet />
			</>
		);
	}
};