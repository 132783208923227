import {useEffect, useState} from "react";

export default function useDoubtFulQueries(projectId) {
    const [loading, setLoading] = useState(true);
    const [doubtFulQueries, setDoubtFulQueries] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        let isMounted = true;

        (async () => {
            setLoading(true);

            try {
                const queryParams = new URLSearchParams();
                queryParams.append('projectId', projectId);

                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/projects/' + projectId + '/doubtFulQueries', {
                    method: "GET",
                    credentials: "include",
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const {doubtFulQueries} = await response.json();
                    isMounted && setDoubtFulQueries(doubtFulQueries);
                }
                else {
                    throw new Error("Wrong status code.");
                }
            }
            catch (e) {
                console.error(e);
            }
            finally {
                isMounted && setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        }
    }, [projectId]);

    return {
        loading,
        doubtFulQueries
    };
}