import Spinner from "../../components/Spinner";
import useProjects from "../../hooks/useProjects";

export default function ProjectInput({inputs, onChange}) {
    const {loading, projects} = useProjects({"wt.name": "GLT"});

    return (
        <div className={"col"}>
            {loading ? <Spinner />:
                <>
                    <label htmlFor={`projectId`} className={"form-label"}>Project:</label>
                    <select className={"form-select"} name={`projectId`} id={`projectId`}
                            value={inputs.projectId} onChange={onChange} required={true}>
                        <option value="">Select a project</option>
                        {projects.map(value => {
                            return <option value={value.id} key={value.id}>{value.name}</option>;
                        })}
                    </select>
                </>
            }
        </div>
    );
}