import {useEffect, useState} from "react";

export default function useProjects(filters) {
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        let isMounted = true;

        (async () => {
            setLoading(true);

            try {
                const queryParams = new URLSearchParams();

                for (const prop in filters) {
                    queryParams.append(prop, filters[prop]);
                }

                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/projects?' + queryParams.toString(), {
                    method: "GET",
                    credentials: "include",
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const {projects} = await response.json();
                    isMounted && setProjects(projects);
                }
                else {
                    throw new Error("Wrong status code.");
                }
            }
            catch (e) {
                console.error(e);
            }
            finally {
                isMounted && setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        }
    }, []);

    return {
        loading,
        projects
    };
}