import {createContext, useEffect, useState} from "react";

export const authContext = createContext({
	user: {},
	setUser: (user) => {},
	loading: false
});

export function AuthProvider({children}) {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);

			try {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/auth', {
					method: "GET",
					credentials: "include"
				});

				console.log(response.status);

				if (response.status === 200) {
					const {user} = await response.json();
					isMounted && setUser(user);
				}
			}
			catch (e) {
				console.error(e);
			}

			isMounted && setLoading(false);
		})();

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<authContext.Provider value={{
			user,
			setUser,
			loading
		}}>
			{children}
		</authContext.Provider>
	);
}
