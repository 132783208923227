import Spinner from "../../components/Spinner";
import useLanguagePairs from "../../hooks/useLanguagePairs";

export default function LanguagePairInput({languagePairId, onChange}) {
    const {loading, languagePairs} = useLanguagePairs();

    return (
        <div className={"mb-3"}>
            {loading ? <Spinner />:
                <>
                    <label htmlFor={`languagePairId`} className={"form-label"}>Language pair:</label>
                    <select className={"form-select"} name={`languagePairId`} id={`languagePairId`}
                            value={languagePairId} onChange={onChange} required={true}>
                        <option value="">Select a language pair</option>
                        {languagePairs.map(value => {
                            return <option value={value.id} key={value.id}>{value.source} --> {value.target}</option>;
                        })}
                    </select>
                </>
            }
        </div>
    );
}