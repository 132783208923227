import useDoubtFulQueries from "../../hooks/useDoubtFulQueries";
import Spinner from "../../components/Spinner";
import DoubtFulQueryInput from "./DoubtFulQueryInput";

export default function DoubtFulQueriesInput({onChange, inputs, projectId}) {
    const {doubtFulQueries, loading} = useDoubtFulQueries(projectId);

    return (
        <div className={""}>
            {loading ? <Spinner />:
                <>
                    <DoubtFulQueryInput doubtFulQueries={doubtFulQueries} onChange={onChange} doubtFulQueriesSelected={inputs.doubtFulQueries1} batchNumber={1}/>
                    <DoubtFulQueryInput doubtFulQueries={doubtFulQueries} onChange={onChange} doubtFulQueriesSelected={inputs.doubtFulQueries2} batchNumber={2}/>
                    <DoubtFulQueryInput doubtFulQueries={doubtFulQueries} onChange={onChange} doubtFulQueriesSelected={inputs.doubtFulQueries3} batchNumber={3}/>
                </>
            }
        </div>
    );
}