import Container from "../../components/Container";
import {useRef, useState} from "react";
import DoubtFulQueriesInput from "./DoubtFulQueriesInput";
import LanguagePairInput from "./LanguagePairInput";
import FileInput from "./FileInput";
import AmountsInput from "./AmountsInput";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";
import Success from "../../components/Alerts/Success";
import DurationInput from "./DueDateInput";
import ProjectInput from "./ProjectInput";
import BatchInput from "./BatchInput";
import UnitValueInput from "./UnitValueInput";

export default function JobCreation() {
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        languagePairId: "",
        projectId: "",
        batchName: "",
        numberOfRows1: "300",
        numberOfRows2: "80",
        numberOfRows3: "400",
        doubtFulQueries1: [],
        doubtFulQueries2: [],
        doubtFulQueries3: [],
        duration1: "2",
        duration2: "18",
        duration3: "18",
        unitValue1: "0.05",
        unitValue2: "1.25",
        unitValue3: "0.2"
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const fileRef = useRef();

    function onChange(evt) {
        switch (evt.target.tagName) {
            case "INPUT":
                switch (evt.target.type) {
                    case "checkbox":
                        setInputs(prev => {
                            return {
                                ...prev,
                                [evt.target.name]: evt.target.checked
                            }
                        });
                        break;
                    default:
                        setInputs(prev => {
                            return {
                                ...prev,
                                [evt.target.name]: evt.target.value
                            }
                        });
                }
                break;
            case "SELECT":
                if (evt.target.multiple) {
                    setInputs(prev => {
                        return {
                            ...prev,
                            [evt.target.name]: [...evt.target.selectedOptions].map(value => value.value)
                        };
                    });
                }
                else {
                    setInputs(prev => {
                        return {
                            ...prev,
                            [evt.target.name]: evt.target.value
                        };
                    });
                }

                break;
            default:
                break;
        }
    }

    async function onSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('file', fileRef.current.files[0]);

            for (const prop in inputs) {
                if (Array.isArray(inputs[prop])) {
                    for (const item of inputs[prop]) {
                        formData.append(prop + '[]', item)
                    }
                }
                else {
                    formData.append(prop, inputs[prop]);
                }
            }

            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/processes', {
                method: "POST",
                body: formData,
                credentials: "include"
            });
            if (response.status === 201) {
                setSuccess(true);
            }
            else if (response.status === 409) {
                setError("You already started a process using this batch name.");
            }
            else if (response.status === 410) {
                const errors = (await response.json()).errors.map(value => <p>{value}</p>);
                setError(errors);
            }
            else {
                throw new Error("Wrong status");
            }
        }
        catch (e) {
            console.error(e);
            setError("Something went wrong while trying to start this process.")
        }

        setLoading(false);
    }
    return (
        <Container>
            <form onSubmit={onSubmit}>
                {error && <Error message={error} />}
                {success && <Success message={"Successfully started process."} />}
                <DurationInput onChange={onChange} inputs={inputs} />
                <AmountsInput onChange={onChange} inputs={inputs} />
                <UnitValueInput onChange={onChange} inputs={inputs} />

                <div className="row mb-3">
                    <ProjectInput onChange={onChange} inputs={inputs} />
                    <BatchInput onChange={onChange} inputs={inputs} />
                </div>

                {inputs.projectId &&
                    <DoubtFulQueriesInput inputs={inputs} onChange={onChange} projectId={inputs.projectId} />
                }
                <LanguagePairInput onChange={onChange} languagePairId={inputs.languagePairId} />
                <FileInput setInputs={setInputs} fileRef={fileRef} />


                <button className={"btn btn-success w-100"} type={"submit"} disabled={loading}>{loading && <Spinner small={true} />} Start process</button>
            </form>
        </Container>
    );
};
