import React from "react";
import {Outlet} from "react-router-dom";

export default function Background() {
    return (
        <>
            <div className={"d-flex justify-content-between align-items-center container-fluid pt-3"}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 1297.14 554.52">
                        <path
                            d="M311.43,432h40.81c.33,18.21,17.53,34.73,43.16,34.73,23.61,0,38.79-14.5,38.79-32.71,0-16.19-13.83-25.63-33.39-31l-29-8.09c-49.92-12.82-58.69-42.83-58.69-63.07,0-41.14,36.09-69.13,78.92-69.13,42.49,0,75.88,26.3,75.88,69.81H427.1c0-19.23-14.5-33.05-36.08-33.05-19.9,0-36.42,13.49-36.42,31.7,0,6.41,2,19.9,28.66,27l27.66,8.09c36.42,10.12,64.41,29,64.41,66.1,0,42.5-35.41,70.83-80.94,70.83C344.82,503.2,311.43,471.5,311.43,432Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M528.27,418.21V266.78h41.15V416.52c0,33.05,21.92,47.56,46.54,47.56,24.28,0,46.2-14.51,46.2-47.56V266.78h41.15V418.21c0,53.29-40.81,85-87.35,85C569.08,503.2,528.27,471.5,528.27,418.21Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M1037.85,499.15H996.71L978.16,343,915.43,472.84H882L819,343.34,800.09,499.15H759l28.33-232.37H823l75.54,159.86,75.21-159.86h35.75Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M1362.28,499.15h-41.14L1302.59,343l-62.73,129.84h-33.39l-63.06-129.5-18.89,155.81h-41.14l28.32-232.37h35.75L1223,426.64l75.2-159.86H1334Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M1565,499.15l-20.23-57h-91.4l-19.9,57h-43.84l87.35-232.37h44.52l87,232.37Zm-99.15-92.74h66.44l-33-94.43Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path d="M354.7,776.46h90.38v36.76H313.55V580.85H354.7Z" transform="translate(-311.43 -262.74)"
                              fill="#2519ff"></path>
                        <path d="M492,813.22V580.85H533.1V813.22Z" transform="translate(-311.43 -262.74)"
                              fill="#2519ff"></path>
                        <path
                            d="M732.74,580.85h41.15V813.22H736.45L643.71,656.73V813.22H602.57V580.85h38.78L732.74,736Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M828.52,697.54c0-66.1,51.93-120.74,118.37-120.74,33.39,0,63.07,13.49,83.3,34.74l-29.67,26a75.76,75.76,0,0,0-53.63-22.6c-43.5,0-76.89,37.77-76.89,82.63,0,44.18,33,82.62,77.57,82.62,34.4,0,66.1-22.25,66.1-52.27H947.23v-34.4h111.29c0,84.65-45.53,123.77-110.28,123.77C879.78,817.26,828.52,763,828.52,697.54Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M1106.41,732.28V580.85h41.14V730.59c0,33,21.92,47.55,46.54,47.55,24.29,0,46.21-14.5,46.21-47.55V580.85h41.14V732.28c0,53.28-40.81,85-87.35,85C1147.22,817.26,1106.41,785.56,1106.41,732.28Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                        <path
                            d="M1503.69,776.12h104.88v37.1H1462.88V757.57h-77.57l-30,55.65h-48.23l129.5-232.37H1605.2V618H1503.69v62.73h88.69V717.1h-88.69Zm-98.48-55.65h57.67V618.28h-2.36Z"
                            transform="translate(-311.43 -262.74)" fill="#2519ff"></path>
                    </svg>
                </div>

                <div style={{marginRight: '116.95px'}}>
                    <h1 className={"text-center m-0"}>Flow tool</h1>
                </div>
                <div>

                </div>
            </div>
        </>
    );
};