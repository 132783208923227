export default function AmountsInput({onChange, inputs}) {
    return (
        <div className={"row mb-3"}>
            <div className="col">
                <label htmlFor="numberOfRows1" className={"form-label"}>Number of rows per job for flow 1:</label>
                <input type={"number"} id={"numberOfRows1"} name={"numberOfRows1"} className={"form-control"} value={inputs.numberOfRows1} onChange={onChange} required={true}/>
            </div>
            <div className="col">
                <label htmlFor="numberOfRows2" className={"form-label"}>Number of rows per job for flow 2:</label>
                <input type={"number"} id={"numberOfRows2"} name={"numberOfRows2"} className={"form-control"} value={inputs.numberOfRows2} onChange={onChange} required={true}/>
            </div>
            <div className="col">
                <label htmlFor="numberOfRows3" className={"form-label"}>Number of rows per job for flow 3:</label>
                <input type={"number"} id={"numberOfRows3"} name={"numberOfRows3"} className={"form-control"} value={inputs.numberOfRows3} onChange={onChange} required={true}/>
            </div>
        </div>
    );
}