import {useEffect, useState} from "react";

import a1 from '../../sounds/security-alarm-80493.mp3';
import a2 from '../../sounds/emergency-alarm-69780.mp3';
import a3 from '../../sounds/alarm-26718.mp3';

import useSound from "use-sound";
import {Shake} from "reshake";

export default function UnitValueInput({inputs, onChange}) {
    const [confirmedCount, setConfirmedCount] = useState(0);
    const [play1, {stop: stop1}] = useSound(a1);
    const [play2, {stop: stop2}] = useSound(a2);
    const [play3, {stop: stop3}] = useSound(a3);

    useEffect(() => {
        if (confirmedCount === 1) {
            play1();
        }
        else if (confirmedCount === 2) {
            play2();
        }
        else if (confirmedCount === 3) {
            play3();
        }
        else if (confirmedCount === 4) {
            stop1();
            stop2();
            stop3();
        }
    }, [confirmedCount]);

    return (
        <div className={"mb-3"}>
            <Shake active={confirmedCount > 0 && confirmedCount < 4} fixed={false} fixedStop={true} >
                <div className="row">
                    <div className={"col"}>
                        <label htmlFor={`unitValue1`} className={"form-label"}>Unit value 1:</label>
                        <input type={"number"} step={0.01} disabled={confirmedCount !== 4} id={"unitValue1"}
                               name={"unitValue1"} className={"form-control"}
                               value={inputs.unitValue1}
                               onChange={onChange} required={true}/>
                    </div>
                    <div className={"col"}>
                        <label htmlFor={`unitValue2`} className={"form-label"}>Unit value 2:</label>
                        <input type={"number"} step={0.01} disabled={confirmedCount !== 4} id={"unitValue2"}
                               name={"unitValue2"} className={"form-control"}
                               value={inputs.unitValue2}
                               onChange={onChange} required={true}/>
                    </div>
                    <div className={"col"}>
                        <label htmlFor={`unitValue3`} className={"form-label"}>Unit value 3:</label>
                        <input type={"number"} step={0.01} disabled={confirmedCount !== 4} id={"unitValue3"}
                               name={"unitValue3"} className={"form-control"}
                               value={inputs.unitValue3}
                               onChange={onChange} required={true}/>
                    </div>
                </div>
                <button className={"btn btn-danger w-100 mt-3"} type={"button"} disabled={confirmedCount === 4}
                        onClick={evt => {
                            setConfirmedCount(prev => prev + 1);
                        }}>
                    {confirmedCount === 0 && 'Edit unit values'}
                    {confirmedCount === 1 && 'Are you sure?'}
                    {confirmedCount === 2 && 'Are you really sure?'}
                    {confirmedCount === 3 && 'Are you extremely sure?'}
                    {confirmedCount === 4 && 'Go ahead'}
                </button>
            </Shake>
        </div>
    );
}