
export default function DoubtFulQueryInput({doubtFulQueries, doubtFulQueriesSelected, batchNumber, onChange}) {
    return (
        <div className={"mb-3"}>
            <label htmlFor={`doubtFulQueries${batchNumber}`} className={"form-label"}>Doubtful queries for flow {batchNumber}:</label>
            <select className={"form-select"} multiple={true} name={`doubtFulQueries${batchNumber}`} id={`doubtFulQueries${batchNumber}`} value={doubtFulQueriesSelected} onChange={onChange} required={false}>
                {doubtFulQueries.map(value => {
                    return <option key={value.id} value={value.id}>{value.name}</option>
                })}
            </select>
        </div>
    );
}