export default function DurationInput({inputs, onChange}) {

    return (
        <div className={"mb-3 row"}>
            <div className={"col"}>
                <label htmlFor={`duration1`} className={"form-label"}>Deadline Flow 1 (hours):</label>
                <input type={"number"} id={"duration1"} name={"duration1"} className={"form-control"} value={inputs.duration1}
                       onChange={onChange} required={true}/>
            </div>
            <div className={"col"}>
                <label htmlFor={`duration2`} className={"form-label"}>Deadline Flow 2 (hours):</label>
                <input type={"number"} id={"duration2"} name={"duration2"} className={"form-control"} value={inputs.duration2}
                       onChange={onChange} required={true}/>
            </div>
            <div className={"col"}>
                <label htmlFor={`duration3`} className={"form-label"}>Deadline Flow 3 (hours):</label>
                <input type={"number"} id={"duration3"} name={"duration3"} className={"form-control"} value={inputs.duration3}
                       onChange={onChange} required={true}/>
            </div>
        </div>
    );
}