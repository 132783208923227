export default function Spinner({small = false}) {
	let className = "spinner-grow";

	if (small) {
		className += " spinner-grow-sm";
	}

	return (
		<div className={className} role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
	);
};
